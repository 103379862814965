<template>
    <div class="">
      <div class="d-flex gap align-items-center">
        <!-- Border Size -->
        <div v-if="isBorder" class="editor-select-color" title="Border">
          <svg  width="20" height="19" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.6667 2H3.33333C2.59333 2 2 2.59333 2 3.33333V12.6667C2 13.0203 2.14048 13.3594 2.39052 13.6095C2.64057 13.8595 2.97971 14 3.33333 14H12.6667C13.0203 14 13.3594 13.8595 13.6095 13.6095C13.8595 13.3594 14 13.0203 14 12.6667V3.33333C14 2.59333 13.4 2 12.6667 2ZM12.6667 3.33333V12.6667H3.33333V3.33333H12.6667Z" fill="#B3AFB6"/>
          </svg>
          <input 
            type="number" 
            v-model.number="internalBorderSize" 
            class="editor-color-input BorderSize w-100" 
            name="borderSize"
          />
        </div>
  
        <!-- Border Radius -->
        <div v-if="isRadius" class="editor-select-color" title="Border Radius">
          <svg width="20"  height="19" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.1666 12.6666H14.5V14H13.1666V12.6666ZM13.1666 11.3334H14.5V10H13.1666V11.3334ZM2.5 8.66664H3.83334V7.33334H2.5V8.66664ZM2.5 11.3334H3.83334V10H2.5V11.3334ZM2.5 6H3.83334V4.66666H2.5V6ZM2.5 3.33334H3.83334V2H2.5V3.33334ZM5.16666 3.33334H6.5V2H5.16666V3.33334ZM10.5 14H11.8334V12.6666H10.5V14ZM7.83334 14H9.16664V12.6666H7.83334V14ZM10.5 14H11.8334V12.6666H10.5V14ZM5.16666 14H6.5V12.6666H5.16666V14ZM2.5 14H3.83334V12.6666H2.5V14ZM14.5 5.33334C14.5 3.49334 13.0066 2 11.1666 2H7.83334V3.33334H11.1666C12.2666 3.33334 13.1666 4.23334 13.1666 5.33334V8.66664H14.5V5.33334Z" fill="#B3AFB6"/>
          </svg>
          <input 
            type="number" 
            v-model.number="internalBorderRadius" 
            class="editor-color-input w-100 border-radius" 
            name="borderRadius"
          />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      borderSize: {
        type: [Number, String],
        default: 1
      },
      borderRadius: {
        type: [Number, String],
        default: 0
      },
      isRadius:{
        type:Boolean,
        default:true
      },
      isBorder:{
        type:Boolean,
        default:true
      }
    },
    data() {
      return {
        internalBorderSize: this.borderSize,
        internalBorderRadius: this.borderRadius
      };
    },
    watch: {
      // Sync props with internal values
      borderSize(newValue) {
        this.internalBorderSize = newValue;
      },
      borderRadius(newValue) {
        this.internalBorderRadius = newValue;
      },
      // Emit changes to parent
      internalBorderSize(newSize) {
        this.$emit('update:borderSize', newSize);
      },
      internalBorderRadius(newRadius) {
        this.$emit('update:borderRadius', newRadius);
      }
    }
  };
  </script>
  
  <style scoped>
  .editor-select-color {
    border-radius: 6px;
    display: flex;
    align-items: center;
    background: #F7F7F7;
    padding: 6px;
    gap: 10px;
  }
  .editor-color-input {
    outline: none;
    border: none;
    background: transparent;
  }
  .editor-select-color:focus-within {
    box-shadow: 0px 0px 1px 1px var(--secondary-color);
  }
  </style>
  